<template>
  <v-container>
    <v-row>
      <h3>Export / Import products and menu</h3>
    </v-row>
    <v-row>
      <v-tabs v-model="tab" light background-color="blue darken-1" dark>
        <v-tab>
          Product
        </v-tab>
        <v-tab>
          Menu
        </v-tab>
        <v-tab>
          Modifiers
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="w-100">
        <v-tab-item>
          <export-products :uid="uid" :userProfile="userProfile" />
        </v-tab-item>
        <v-tab-item>
          <export-menus :uid="uid" :userProfile="userProfile" />
        </v-tab-item>
        <v-tab-item>
          <export-modifiers :uid="uid" :userProfile="userProfile" />
        </v-tab-item>
      </v-tabs-items>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
    ExportProducts: () => import('./ExportProducts'),
    ExportMenus: () => import('./ExportMenus'),
    ExportModifiers: () => import('./ExportModifiers')
  },
  computed: {
    ...mapGetters({
      user: 'user',
      myProfile: 'profile',
      loading: 'loading',
      isAdmin: 'isAdmin',
      userProfile: 'userProfile'
    })
  },
  data() {
    return {
      tab: null,
      uid: null
    }
  },
  async mounted() {
    this.uid =
      this.isAdmin && this.$route.params.uid
        ? this.$route.params.uid
        : this.user
        ? this.user.id
        : null
    // await this.$store.dispatch('loadUserProfile', { uid: this.uid })
  }
}
</script>
